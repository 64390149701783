// src/Utils/tokenRefresh.ts

import { BASE_API_URL } from "../Constants/constants";

export const refreshToken = (): Promise<boolean> => {
  console.log("Refreshing token");
  return new Promise((resolve) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    iframe.src = `${BASE_API_URL}/login?background=true`;

    iframe.onload = (ev: Event) => {
      console.log("IFrame loaded", ev);
      // get the current url of the iframe
      const currentUrl = new URL(iframe.contentWindow?.location.href || "");
      console.log(`Current URL: ${currentUrl}`);
      if (currentUrl.pathname === "/") {
        console.log(`Login refresh success detected`);
        document.body.removeChild(iframe);
        resolve(true);
      }
    };
    // Set a timeout to remove the iframe and resolve false if no message is received
    setTimeout(() => {
      document.body.removeChild(iframe);
      console.log(`RefreshToken timed out`);
      resolve(false);
    }, 30000); // 30 seconds timeout
  });
};
