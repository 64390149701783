import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { authFetch } from "../../Utils/authFetch";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ErrorDisplay from "../../components/ErrorDisplay";
import {
  SortInterface,
  StatusCellProps,
  Transaction,
} from "../../Constants/interfaces";
import { EDIParser } from "../TransactionsParser/highboundParser";
import { AUTH_ERROR } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { extractZipContents } from "../../Utils/zipUtils";
import { showAlert } from "../../Utils/alerts";

const ExpandedRow = styled.tr`
  background-color: #f9f9f9;
`;

const ExpandedCell = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #ecf0f1;
`;

const SegmentContainer = styled.div`
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
`;

const SegmentHeader = styled.div`
  background-color: #f2f2f2;
  padding: 0.5rem;
  font-weight: bold;
`;

const SegmentContent = styled.div`
  padding: 0.5rem;
`;

const SegmentRow = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  &:last-child {
    border-bottom: none;
  }
`;

const SegmentKey = styled.div`
  flex: 1;
  padding: 0.25rem;
  font-weight: bold;
`;

const SegmentValue = styled.div`
  flex: 2;
  padding: 0.25rem;
  word-break: break-all;
`;

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Arial", sans-serif;
  padding-top: 100px;
`;

const PageTitle = styled.h1`
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Section = styled.section`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #34495e;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`;

const FileInput = styled.input`
  margin-bottom: 1rem;
`;

const UploadButton = styled.button`
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 24px;
  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 1rem;
  text-align: left;
  background-color: #f2f2f2;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #ecf0f1;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-left: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const FilterSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 150px;
`;

const SearchInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 200px;
`;

const CheckboxCell = styled.td`
  text-align: center;
`;

const ResubmitButton = styled(UploadButton)`
  margin-top: 1rem;
  background-color: #2ecc71;

  &:hover {
    background-color: #27ae60;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const getStatusColor = (status: string): string => {
  switch (status) {
    case "completed":
      return "green";
    case "error":
      return "red";
    case "in_progress":
      return "orange";
    default:
      return "inherit";
  }
};

const StatusCell = styled(Td)<StatusCellProps>`
  color: ${(props) => getStatusColor(props.$statusVal)};
`;

const TransactionsUpload: React.FC = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [files, setFiles] = useState<FileList | null>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [processingStatus, setProcessingStatus] =
    useState<Map<string, string>>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const { error, handleError, clearError } = useErrorHandler();
  const [filters, setFilters] = useState({
    claim_h_uuid: "",
    transaction_type: "",
    transaction_date: "",
    processing_state: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState<SortInterface | null>(null);
  const [selectedTransactions, setSelectedTransactions] = useState<Set<string>>(
    new Set()
  );
  const [selectAll, setSelectAll] = useState(false);

  const [expandedTransaction, setExpandedTransaction] = useState<string | null>(
    null
  );

  const navigate = useNavigate();

  const handleAuthError = (e: Error) => {
    if (((e as any).message as any) === AUTH_ERROR) {
      navigate("/login");
    }
  };
  const toggleTransaction = (claimId: string) => {
    if (expandedTransaction === claimId) {
      setExpandedTransaction(null);
    } else {
      setExpandedTransaction(claimId);
    }
  };

  const parseFile = (f: File): Promise<any> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          let ediFile = e.target?.result as string;
          resolve(ediFile);
        } catch (er) {
          reject(er);
        }
      };
      reader.readAsText(f);
    });
  };

  const uploadJSON = async (jsonData: any[]) => {
    await authFetch("/transactions/upload", {
      method: "POST",
      body: JSON.stringify(jsonData),
    });
  };

  const fetchProcessingStatus = async (claimHUuids: string[]) => {
    try {
      const queryParams = new URLSearchParams();
      claimHUuids.forEach((uuid) => queryParams.append("claim_h_uuid", uuid));

      const data = await authFetch(
        `/claims-analysis-processing?${queryParams.toString()}`
      );
      const processingMap: Map<string, string> = new Map(
        data.map((item: any) => [item.claim_h_uuid, item.processing_state])
      );
      setProcessingStatus(processingMap);
      setTransactions((prevDetails) =>
        prevDetails.map((trans) => ({
          ...trans,
          processing_state:
            processingMap.get(trans.claim_h_uuid) ||
            trans.processing_state ||
            "",
        }))
      );
    } catch (er) {
      handleError(er);
      handleAuthError(er as Error);
    }
  };

  const fetchTransactions = async () => {
    try {
      let { transactions: data, totalCount: total } = await authFetch(
        `/transactions?page=${page}&pageSize=${pageSize}`
      );
      setTransactions(data);
      setTotalCount(total);

      // Fetch processing status for visible transactions
      const visibleClaimHUuids = data.map(
        (trans: Transaction) => trans.claim_h_uuid
      );
      await fetchProcessingStatus(visibleClaimHUuids);
    } catch (er) {
      handleError(er);
      handleAuthError(er as Error);
    }
  };

  useEffect(() => {
    fetchTransactions();
    const intervalId = setInterval(() => {
      const visibleClaimHUuids = transactions.map(
        (trans) => trans.claim_h_uuid
      );
      fetchProcessingStatus(visibleClaimHUuids);
    }, 30000);
    return () => clearInterval(intervalId);
  }, [navigate, page, pageSize]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(event.target.files);
    }
  };

  const handleUpload = async () => {
    if (!files || files.length === 0) return;

    setIsUploading(true);
    setUploadProgress(0);

    try {
      let filesToProcess: { name: string; content: string }[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.name.toLowerCase().endsWith(".zip")) {
          const zipContents = await extractZipContents(file);
          // want the name without the path
          let additionalFilesToProcess = Array.from(zipContents.entries()).map(
            ([name, content]) => ({
              name: name.split("/").pop() || name,
              content,
            })
          );
          filesToProcess = [...filesToProcess, ...additionalFilesToProcess];
        } else {
          const content = await parseFile(file);
          filesToProcess.push({ name: file.name, content });
        }
      }

      let batchedTransactions = [];
      for (let f = 0; f < filesToProcess.length; f++) {
        const { name, content } = filesToProcess[f];
        let ediTransactions = await new EDIParser().parse(content);
        ediTransactions = ediTransactions.map((ttt) => {
          return { ...ttt, file_name: name };
        });
        while (ediTransactions.length > 0) {
          batchedTransactions.push(ediTransactions.splice(0, 50));
        }
      }
      console.log(batchedTransactions);

      for (let x = 0; x < batchedTransactions.length; x++) {
        let batch = batchedTransactions[x];
        await uploadJSON(batch);
        setUploadProgress(((x + 1) / batchedTransactions.length) * 100);
        console.log(
          `Uploaded batch ${x + 1} of ${batchedTransactions.length} with ${
            batch.length
          } transactions from files ${batch.map((b) => b.file_name).join(", ")}`
        );
        if (x % 5 === 0) {
          fetchTransactions();
        }
      }
      await fetchTransactions();

      setFiles(null);
      setUploadProgress(0);
    } catch (er) {
      handleError(er);
      handleAuthError(er as Error);
    } finally {
      setIsUploading(false);
      setFiles(null);
    }
  };

  const handleFilterChange = (column: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  const handleSort = (key: keyof Transaction) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const filteredAndSortedTransactions = useMemo(() => {
    let result = transactions.filter((trans) => {
      const matchesFilters = Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        const transValue = String(trans[key as keyof Transaction]);
        return transValue === value;
      });

      const matchesSearch = Object.values(trans).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );

      return matchesFilters && matchesSearch;
    });

    if (sortConfig !== null) {
      result.sort((a, b) => {
        if ((a[sortConfig.key] || 0) < (b[sortConfig.key] || 0)) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if ((a[sortConfig.key] || 0) > (b[sortConfig.key] || 0)) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return result;
  }, [transactions, filters, searchTerm, sortConfig]);

  const filterOptions = useMemo(() => {
    const options: { [key: string]: Set<string> } = {
      claim_h_uuid: new Set(),
      transaction_type: new Set(),
      transaction_date: new Set(),
      processing_state: new Set(),
    };

    transactions.forEach((trans) => {
      Object.keys(options).forEach((key) => {
        options[key].add(String(trans[key as keyof Transaction]));
      });
    });

    return options;
  }, [transactions]);

  const handleSelectTransaction = (id: string) => {
    setSelectedTransactions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTransactions(new Set());
    } else {
      const allVisibleTransationIds = filteredAndSortedTransactions.map(
        (trans) => trans.claim_h_uuid
      );
      setSelectedTransactions(new Set(allVisibleTransationIds));
    }
    setSelectAll(!selectAll);
  };

  const handleResubmitForAnalysis = async () => {
    if (selectedTransactions.size === 0) {
      showAlert(
        "No Transactions Selected",
        "Please select at least one transaction to resubmit for analysis.",
        "warning"
      );
      return;
    }

    try {
      const claimsToResubmit = Array.from(selectedTransactions);
      await authFetch("/claims-analysis-processing", {
        method: "POST",
        body: JSON.stringify({ claim_h_uuids: claimsToResubmit }),
      });
      showAlert(
        "Success",
        `${selectedTransactions.size} claim${
          selectedTransactions.size !== 1 ? "s" : ""
        } resubmitted for analysis.`,
        "success"
      );
      setSelectedTransactions(new Set());
      setSelectAll(false);
      await fetchProcessingStatus(claimsToResubmit);
    } catch (er) {
      handleError(er);
      handleAuthError(er as Error);
    }
  };
  const formatDate = (dateString: string) => {
    // Parse the date string
    let ds = dateString + "";

    const year = parseInt(ds.substring(0, 2) + "") + 2000;
    const month = parseInt(ds.substring(2, 4) + "") - 1; // JS months are 0-indexed
    const day = parseInt(ds.substring(4, 6) + "");

    // Create a Date object
    const date = new Date(year, month, day);

    // Format options
    const options = { year: "numeric", month: "long", day: "numeric" } as any;

    // Return the formatted date
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const allVisible = filteredAndSortedTransactions.every((trans) =>
      selectedTransactions.has(trans.claim_h_uuid)
    );
    setSelectAll(allVisible);
  }, [selectedTransactions, filteredAndSortedTransactions]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const totalPages = Math.ceil(totalCount / pageSize);
  return (
    <PageContainer>
      <PageTitle>Transaction Upload and Management</PageTitle>
      <ErrorDisplay error={error} onClose={clearError} />

      <Section>
        <SectionTitle>Upload Transactions</SectionTitle>
        <FileInput
          type="file"
          accept=".edi,.CLM,.zip"
          onChange={handleFileChange}
          multiple
        />
        <UploadButton onClick={handleUpload} disabled={!files || isUploading}>
          Upload and Process
        </UploadButton>
        {isUploading && (
          <div>
            <progress value={uploadProgress} max="100" />
            <span style={{ marginLeft: "20px" }}>
              {Math.round(uploadProgress)}%
            </span>
          </div>
        )}
      </Section>

      {transactions.length > 0 && (
        <Section>
          <SectionTitle>Transaction Details</SectionTitle>
          <FilterContainer>
            <SearchInput
              type="text"
              placeholder="Search all fields..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {Object.entries(filters).map(([key, value]) => (
              <FilterSelect
                key={key}
                value={value}
                onChange={(e) => handleFilterChange(key, e.target.value)}
              >
                <option value="">All {key.replace("_", " ")}</option>
                {Array.from(filterOptions[key]).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </FilterSelect>
            ))}
          </FilterContainer>
          <Table>
            <thead>
              <tr>
                <Th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </Th>
                <Th>ID</Th>
                <Th onClick={() => handleSort("file_name")}>File Name</Th>
                <Th onClick={() => handleSort("transaction_type")}>
                  Transaction Type
                </Th>
                <Th onClick={() => handleSort("transaction_date")}>
                  Transaction Date
                </Th>
                <Th onClick={() => handleSort("processing_state")}>
                  Processing Status
                </Th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedTransactions.map((trans, index) => (
                <React.Fragment
                  key={`${trans.unique_hash}-${trans.claim_h_uuid}`}
                >
                  <tr key={`${trans.unique_hash}-${trans.claim_h_uuid}`}>
                    <CheckboxCell>
                      <input
                        type="checkbox"
                        checked={selectedTransactions.has(trans.claim_h_uuid)}
                        onChange={(e) => {
                          handleSelectTransaction(trans.claim_h_uuid);
                        }}
                      />
                    </CheckboxCell>
                    <Td>{trans.id}</Td>
                    <Td
                      onClick={(e) => {
                        toggleTransaction(trans.claim_h_uuid);
                      }}
                    >
                      {trans.file_name || trans.claim_h_uuid}
                    </Td>
                    <Td>{trans.transaction_type}</Td>
                    <Td>{formatDate(trans.transaction_date || "")}</Td>
                    <StatusCell
                      $statusVal={trans.processing_state || "not_started"}
                    >
                      {trans.processing_state || "Loading..."}
                    </StatusCell>
                  </tr>
                  {expandedTransaction === trans.claim_h_uuid && (
                    <ExpandedRow
                      key={`Expanded-${index}-${trans.claim_h_uuid}`}
                    >
                      <ExpandedCell colSpan={5}>
                        {(trans.segments || []).map((segment, segmentIndex) => (
                          <SegmentContainer
                            key={`${trans.claim_h_uuid}-${segment.segment_id}-${trans.unique_hash}`}
                          >
                            <SegmentHeader>
                              {`${segment.segment_id} (${segment.segment_name})`}
                            </SegmentHeader>
                            <SegmentContent>
                              {Array.isArray(segment.data)
                                ? segment.data.map((data, dataIndex) => {
                                    return typeof data === "object"
                                      ? Object.entries(data).map(
                                          ([k, v], subDI) => (
                                            <SegmentRow
                                              key={`${k}-${segment.id}-${trans.claim_h_uuid}-${dataIndex}-${subDI}`}
                                            >
                                              <SegmentKey>{k}</SegmentKey>
                                              <SegmentValue>
                                                {typeof v === "object"
                                                  ? JSON.stringify(v)
                                                  : (v as any)}
                                              </SegmentValue>
                                            </SegmentRow>
                                          )
                                        )
                                      : data;
                                  })
                                : Object.entries(segment.data).map(
                                    ([key, value], di) => (
                                      <SegmentRow
                                        key={`${key}-${segment.id}-${trans.claim_h_uuid}-${di}`}
                                      >
                                        <SegmentKey>{key}</SegmentKey>
                                        <SegmentValue>
                                          {typeof value === "object"
                                            ? JSON.stringify(value)
                                            : value}
                                        </SegmentValue>
                                      </SegmentRow>
                                    )
                                  )}
                            </SegmentContent>
                          </SegmentContainer>
                        ))}
                      </ExpandedCell>
                    </ExpandedRow>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <ResubmitButton onClick={handleResubmitForAnalysis}>
            Resubmit Selected for Analysis
          </ResubmitButton>
          {totalPages > 1 && (
            <PaginationContainer>
              <PageButton
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >
                Previous
              </PageButton>
              <span>
                Page {page} of {totalPages}
              </span>
              <PageButton
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
              >
                Next
              </PageButton>
            </PaginationContainer>
          )}
        </Section>
      )}
    </PageContainer>
  );
};

export default TransactionsUpload;
