// components/ClaimModal.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import Analysis from "./Analysis";
import ServicePayments from "./ServicePayments";
import Notes from "./Notes";
import { ClaimModalProps, CombinedClaim } from "../Constants/interfaces";
import ClaimDetails from "./ClaimDetails";
import ResubmissionAnalysisComponent from "./ResubmissionAnalysis";
import { authFetch } from "../Utils/authFetch";
import Associated835s from "./Associated835s";
import QuestionAndAnswers from "./QuestionAndAnswers";
import { showAlert } from "../Utils/alerts";

const ModalContent = styled.div`
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  transition: background-color 0.2s ease;
  font-size: 16px;

  &:hover {
    background-color: #218838;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TitleText = styled.h1`
  margin: 0;
  margin-bottom: 20px;
`;

const RegenerateButton = styled(StyledButton)`
  background-color: #f39c12;
  &:hover {
    background-color: #e67e22;
  }
  margin-bottom: 20px;
`;

const ClaimModal: React.FC<ClaimModalProps> = ({
  claim,
  handleAddTag,
  handleDeleteTag,
  onClose,
}) => {
  const [resubmissionAnalysis, setResubmissionAnalysis] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchResubmissionAnalysis = async () => {
    setIsLoading(true);
    try {
      const response = await authFetch(
        `/resubmission-analysis?claim_h_uuid=${claim.claim_h_uuid}`
      );
      let analysisData = response.analysis_data;
      // check if string and parse

      if (typeof analysisData === "string") {
        analysisData = JSON.parse(analysisData);
      }

      setResubmissionAnalysis(analysisData);
    } catch (error) {
      console.error("Error fetching resubmission analysis:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchResubmissionAnalysis();
  }, [claim.claim_h_uuid]);

  const handleRegenerateAnalysis = async () => {
    setIsLoading(true);
    try {
      // I'm worried this might timeout
      setResubmissionAnalysis(null);
      let result = await authFetch("/resubmission-analysis", {
        method: "POST",
        body: JSON.stringify({ claim_h_uuid: claim.claim_h_uuid }),
      });
      setResubmissionAnalysis(resubmissionAnalysis.analysis_data);
      fetchResubmissionAnalysis();
    } catch (error) {
      console.error("Error regenerating resubmission analysis:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleShare = (claim: CombinedClaim) => {
  //   const title = claim.claim_h_uuid;
  //   const text = "Here is a claim";
  //   const url = window.location.href;

  //   if (navigator.share) {
  //     navigator
  //       .share({ title, text, url })
  //       .then(() => console.log("Content shared successfully!"))
  //       .catch((e) => console.error("Error sharing:", e));
  //   } else {
  //     navigator.clipboard.writeText(url);
  //     showAlert("Success", "URL copied to clipboard!", "info");
  //   }
  // };

  if (!claim) {
    return null;
  }

  let open = true;

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalContent>
        <ClaimDetails
          claim={claim}
          onTagAdd={(claimHUuid: string, tag: string) => {
            console.log("Tag added:", claimHUuid, tag);
            handleAddTag(claimHUuid, tag);
          }}
          onTagRemove={(claimHUuid: string, tag: string) => {
            console.log("Tag removed:", claimHUuid, tag);
            handleDeleteTag(claimHUuid, tag);
          }}
        />
        <ServicePayments claim={claim} />
        <Analysis claim={claim} />
        <Notes claim={claim} />
        <ResubmissionAnalysisComponent
          analysis={resubmissionAnalysis}
          isLoading={isLoading}
          handleRegenerateAnalysis={handleRegenerateAnalysis}
        />
        <QuestionAndAnswers
          claim={claim}
          resubmissionAnalysis={resubmissionAnalysis}
        />
        <Actions>
          {!isLoading && (
            <RegenerateButton
              onClick={handleRegenerateAnalysis}
              disabled={isLoading}
            >
              Generate AI Next Steps
            </RegenerateButton>
          )}
        </Actions>
        <Associated835s transactions={claim.transactions || []} />
      </ModalContent>
    </Modal>
  );
};

export default ClaimModal;
