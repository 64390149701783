import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { authFetch } from "./authFetch";

const CheckAuthComponent: React.FC = () => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await authFetch("/check-auth");
        setIsValid(true);
      } catch (err: any) {
        setIsValid(false);
        setError(err.message);
      }
    };

    checkAuth();
  }, []);

  if (isValid === null) {
    return <div>Checking Authentication</div>;
  }

  if (error) {
    return (
      <div>
        <Link to="/login">login</Link>
      </div>
    );
  }

  let userName = "";
  try {
    let userInfo = JSON.parse(localStorage.getItem("userToken") || "");
    if (userInfo) {
      userName = userInfo.name;
    }
    console.log(JSON.stringify(userInfo));
  } catch (e) {
    console.log(e);
  }
  return (
    <div>
      {isValid ? (
        <div>Hello {userName}</div>
      ) : (
        <div>
          <Link to="/login">login</Link>.
        </div>
      )}
    </div>
  );
};

export default CheckAuthComponent;
