// AuthRedirect.tsx
import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../Constants/constants";
import {
  LoadingBox,
  LoadingContainer,
  LoadingText,
  Spinner,
} from "../../components/Loading";

const AuthRedirect: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const processAuth = async () => {
      const state = searchParams.get("state");
      const code = searchParams.get("code");
      const scope = searchParams.get("scope");
      const authuser = searchParams.get("authuser");
      const hd = searchParams.get("hd");
      const prompt = searchParams.get("prompt");

      const apiUrl = `${BASE_API_URL}/auth?state=${state}&code=${code}&scope=${scope}&authuser=${authuser}&hd=${hd}&prompt=${prompt}`;
      try {
        const data = await fetch(apiUrl).then((response) => response.json());

        if (data.userToken && data.accessToken) {
          localStorage.setItem(
            "userToken",
            JSON.stringify(data.userToken || "")
          );
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("idToken", data.idToken);

          window.location.href = "/";
        } else {
          throw new Error("Error retrieving user token or access token");
        }
      } catch (error) {
        console.error("Error fetching tokens:", error);
        navigate("/login");
      }
    };
    processAuth();
  }, [navigate]);

  return (
    <LoadingContainer>
      <LoadingBox>
        <LoadingText>Processing login...</LoadingText>
        <Spinner />
      </LoadingBox>
    </LoadingContainer>
  );
};

export default AuthRedirect;
