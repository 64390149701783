import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./Pages/Home/Home";
import LoginPage from "./Pages/Login/Login";
import AuthRedirect from "./Pages/Login/AuthRedirect";
import DashboardPage from "./Pages/Dashboard/Dashboard";
import Header from "./components/Header";
import AnalyticsPage from "./Pages/Analytics/AnalyticsPage";
import TenantManagement from "./Pages/TenantManagement/TenantManagement";
import TransactionsUpload from "./Pages/Transactions/TransactionsUpload";
import TransactionParser from "./Pages/TransactionsParser/TransactionsParser";
import TenantSettings from "./Pages/TenantSettings/TenantSettings";
import GlobalNotes from "./Pages/GlobalNotes/GlobalNotes";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = () => {
    return !!localStorage.getItem("accessToken");
  };

  return isAuthenticated() ? children : <Navigate to="/login" />;
};

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth-redirect" element={<AuthRedirect />} />

          {/* Simplified Route definition */}
          <Route
            path="/dashboard"
            // prettier-ignore
            element={
              (
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute>
                <AnalyticsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tenant-management"
            element={
              <ProtectedRoute>
                <TenantManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/global-notes"
            element={
              <ProtectedRoute>
                <GlobalNotes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tenant-settings"
            element={
              <ProtectedRoute>
                <TenantSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/claim-upload"
            element={
              <ProtectedRoute>
                <TransactionsUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transactions-parser"
            element={
              <ProtectedRoute>
                <TransactionParser />
              </ProtectedRoute>
            }
          />
          {/* Add more protected routes similarly */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
