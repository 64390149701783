// Define TypeScript interfaces for your data

export interface AnalysisProps {
  claim: CombinedClaim;
}

export interface ServicePaymentsProps {
  claim: CombinedClaim;
}
export interface ReSubmissionProps {
  claim: CombinedClaim;
}
export interface ClaimDetailProps {
  claim: CombinedClaim;
  onTagAdd: (claimId: string, tag: string) => void;
  onTagRemove: (claimId: string, tag: string) => void;
}

export interface ClaimsListProps {
  onClaimSelect: (claimId: string) => void;
}

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export interface Tenant {
  id: number;
  tenant_uuid: string;
  tenant_name: string;
  logo_url?: string;
  created_at: Date;
  updated_at: Date;
  company_name?: string;
  company_logo_url?: string;
  company_primary_color?: string;
  company_secondary_color?: string;
  company_email_signature?: string;
  company_notes?: string;
  contact_info?: string;
}

export interface User {
  user_id: number;
  user_uuid: string;
  email: string;
  email_verified: boolean;
  family_name?: string;
  given_name?: string;
  hd?: string;
  full_name?: string;
  picture?: string;
  sub: string;
}

export interface ClaimAnalysis {
  claim_h_uuid: string;
  claim_number: string;
  claim_name: string;
  marker: string;
  patient_name: string;
  tenant_uuid: string;
  transaction_date: Date;
  procedure_date: Date;
  claim_status: string;
  claim_sub_status: string;
  claim_status_summary: string;
  payer_classification: string;
  payer_name: string;
  code: string;
  code_explanation: string;
  procedure_explanation: string;
  charge_amount: number;
  allowed_amount: number;
  paid_amount: number;
  highbound_analysis: string;
  rejection_reason: string;
  resubmission_next_steps: string;
  re_submission_chance: string;
  insurance_type: string;
  uploaded_date: string;
}

export interface UserTenant {
  id: number;
  user_uuid: string;
  tenant_uuid: string;
  created_at: Date;
  updated_at: Date;
}

export interface Admin {
  id: number;
  user_uuid: string;
  admin_level: number;
  created_at: Date;
  updated_at: Date;
}
export interface ErrorState {
  hasError: boolean;
  message: string;
}
export interface ErrorDisplayProps {
  error: ErrorState;
  onClose: () => void;
}

export interface CombinedClaim extends ClaimAnalysis {
  transactions: Transaction[];
  notes: ClaimNotes[];
  tags: string[];
}

export interface Transaction {
  id?: number;
  file_name?: string;
  insert_uuid?: string;
  unique_hash?: string;
  claim_h_uuid: string;
  transaction_type: string;
  transaction_date: string;
  sender_id: string;
  receiver_id: string;
  segments: Segment[];
  tenant_uuid?: string;
  raw_json?: string;
  processing_state?: string;
  file_type?: "835" | "837";
  uploaded_date?: string;
}

export interface Segment {
  id?: number;
  transaction_id?: number;
  segment_id: string;
  segment_name: string;
  data: Record<string, string | number | boolean | string[]>;
  segment_data?: string;
  tenant_uuid?: string;
  containsPHI?: boolean;
}

// First, define the props type for the styled component
export type StatusCellProps = {
  $statusVal: string;
};
export interface SortInterface {
  key: keyof Transaction;
  direction: "ascending" | "descending";
}

export interface ClaimNotes {
  id: number;
  claim_h_uuid: string;
  user_uuid: string;
  user_name: string;
  tenant_uuid: string;
  content: string;
  created_at: Date;
  updated_at: Date;
}
export interface NotesProps {
  claim: CombinedClaim | null;
}

export interface ClaimModalProps {
  claim: CombinedClaim;
  handleAddTag: (claimId: string, tag: string) => void;
  handleDeleteTag: (claimId: string, tag: string) => void;
  onClose: () => void;
}

export type FilterType = "text" | "date" | "number" | "select";

export interface ColumnConfig {
  title: string;
  hidden?: boolean;
  sortable: boolean;
  filterable: boolean;
  filterType?: FilterType;
  options?: string[];
  searchable: boolean;
  truncate?: boolean;
  joinArray?: boolean;
  formatAmount?: boolean;
  formatDate?: boolean;
  width?: string;
}

export const columnConfig: Record<keyof CombinedClaim, ColumnConfig> = {
  tags: {
    title: "Tags",
    sortable: false,
    filterable: false,
    filterType: "select",
    searchable: true,
    joinArray: true,
    width: "150",
  },
  claim_number: {
    title: "Claim Number",
    sortable: true,
    filterable: false,
    filterType: "text",
    searchable: true,
    width: "200",
  },
  claim_name: {
    title: "Procedure",
    sortable: true,
    filterable: false,
    filterType: "text",
    searchable: true,
    width: "150",
  },
  procedure_date: {
    title: "Date of Service",
    sortable: true,
    filterable: true,
    filterType: "date",
    searchable: true,
    formatDate: true,
  },
  patient_name: {
    title: "Patient Name",
    sortable: true,
    filterable: false,
    filterType: "text",
    searchable: true,
    width: "200",
  },
  claim_status: {
    title: "Status",
    sortable: true,
    filterable: true,
    filterType: "select",
    searchable: true,
    width: "50",
    hidden: true,
  },
  claim_sub_status: {
    title: "Status",
    sortable: true,
    filterable: true,
    filterType: "select",
    searchable: true,
    width: "250",
  },
  payer_name: {
    title: "Payer",
    sortable: true,
    filterable: true,
    filterType: "select",
    searchable: true,
    width: "100",
  },
  insurance_type: {
    title: "Insurance Type",
    sortable: true,
    filterable: true,
    filterType: "select",
    searchable: true,
    hidden: false,
    width: "150",
  },
  charge_amount: {
    title: "Charge Amount",
    sortable: true,
    filterable: true,
    filterType: "number",
    searchable: false,
    formatAmount: true,
    width: "150",
  },
  allowed_amount: {
    title: "Allowed Amount",
    sortable: true,
    filterable: true,
    filterType: "number",
    searchable: false,
    formatAmount: true,
    width: "150",
  },
  paid_amount: {
    title: "Paid Amount",
    sortable: true,
    filterable: true,
    filterType: "number",
    searchable: false,
    formatAmount: true,
    width: "150",
  },
  transaction_date: {
    title: "Transaction Date",
    sortable: true,
    filterable: true,
    filterType: "date",
    searchable: false,
    formatDate: true,
    hidden: true,
  },
  code: {
    title: "Code",
    sortable: true,
    filterable: true,
    filterType: "text",
    searchable: true,
    width: "100",
  },

  // Hidden columns
  tenant_uuid: {
    title: "Tenant ID",
    sortable: false,
    filterable: false,
    searchable: false,
    hidden: true,
  },
  transactions: {
    title: "Transactions",
    sortable: false,
    filterable: false,
    searchable: false,
    hidden: true,
  },
  marker: {
    title: "Marker",
    sortable: false,
    filterable: false,
    searchable: false,
    hidden: true,
  },

  payer_classification: {
    title: "Payer",
    sortable: true,
    filterable: true,
    filterType: "select",
    searchable: true,
    hidden: true,
    width: "100",
  },
  claim_status_summary: {
    title: "Summary",
    sortable: false,
    filterable: false,
    searchable: true,
    truncate: true,
    width: "300",
  },
  code_explanation: {
    title: "Code Explanation",
    sortable: false,
    filterable: false,
    searchable: true,
    truncate: true,
    width: "300",
  },

  procedure_explanation: {
    title: "Procedure",
    sortable: false,
    filterable: false,
    searchable: true,
    truncate: true,
    width: "300",
  },
  rejection_reason: {
    title: "Rejection Reason",
    sortable: false,
    filterable: false,
    searchable: true,
    truncate: true,
    width: "300",
  },
  highbound_analysis: {
    title: "Analysis",
    sortable: false,
    filterable: false,
    searchable: true,
    truncate: true,
    width: "300",
  },
  resubmission_next_steps: {
    title: "Next Steps",
    sortable: false,
    filterable: false,
    searchable: true,
    truncate: true,
    hidden: true,
    width: "300",
  },
  re_submission_chance: {
    title: "Resubmission Chance",
    sortable: true,
    filterable: true,
    filterType: "select",
    options: ["Low", "Medium", "High"],
    searchable: true,
    hidden: true,
    width: "300",
  },
  notes: {
    title: "Notes",
    hidden: true,
    sortable: false,
    filterable: false,
    searchable: false,
    width: "300",
  },

  claim_h_uuid: {
    title: "Claim ID",
    sortable: true,
    filterable: false,
    searchable: true,
    width: "200",
  },
  uploaded_date: {
    title: "Uploaded Date",
    sortable: true,
    filterable: true,
    filterType: "date",
    searchable: false,
    formatDate: true,
    hidden: true,
  },
};

export interface ResubmissionAnalysis {
  additional_info_needed: {
    question: string;
    type: string;
    options?: string[];
  }[];
  resubmission_letter: string;
  form_837_suggestion: any;
  next_steps: {
    step: string;
    description: string;
  }[];
  approval_analysis: string;
  citations: Citation[];
}

export interface Citation {
  source: string;
  fileName?: string;
  pageNumber?: string;
  relevance: string;
}

export interface QuestionAndAnswer {
  id: number;
  claim_h_uuid: string;
  question: string;
  answer: string;
  created_at: string;
  updated_at: string;
}

export interface DailyInsight {
  date: Date;
  claimAnalysis: ClaimAnalysis;
  claim_h_uuid: string;
  why_its_interesting: string;
  why_its_important: string;
  claim_summary: string;
  tenant_uuid: string;
  created_at: Date;
}

export const statusTags = [
  "No Action Needed",
  "Needs Action",
  "Actioned",
  "Archived",
];

export const tagColors: { [key: string]: string } = {
  "No Action Needed": "#28a745", // Green
  "Needs Action": "#dc3545", // Red
  Actioned: "#17a2b8", // Blue
  Archived: "#6c757d", // Gray
  // Should be a nice light gray for new
  New: "#c8c9ca",
  // Add more tag colors as needed
};

// Default color for tags not in the mapping
export const defaultTagColor = "#6c757d";

// Function to get the color for a tag
export const getTagColor = (tag: string): string => {
  return tagColors[tag] || defaultTagColor;
};
